// using ES6 modules

import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./screens/Home.js";
import Portfolio from "./screens/Portfolio.js";
import ReactGA from "react-ga";
import ThemeToggleButton from "./components/ThemeToggleButton.js";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

function initializeReactGA() {
  ReactGA.initialize("UA-140777720-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

let changeBodyThemeClass = (target, remove) => {
  document.body.classList.add(target);
  document.body.classList.remove(remove);
};

const useEffectDarkMode = () => {
  const [themeState, setThemeState] = React.useState({
    dark: false,
    hasThemeLoaded: true,
  });
  React.useEffect(() => {
    const lsDark = localStorage.getItem("dark") === "true";
    //toast user about dark mode if first time
    if (localStorage.getItem("firstTime") === null) {
      if (lsDark) {
        //send cool dark mode message
        toast.dark(
          "Dark mode is selected based on your browser settings. You can change it at the top right corner ↗."
        );
      } else {
        toast.info(
          "Light mode is selected based on your browser settings. You can change it at the top right corner ↗."
        );
      }
      localStorage.setItem("firstTime", "false");
    }

    setThemeState({ ...themeState, dark: lsDark, hasThemeLoaded: true });
    // if(lsDark)changeBodyThemeClass("dark", "light");
    //preload class disabled all css transition, so there won't be a flicker on load
    document.body.classList.remove("preload");
  }, []);
  return [themeState, setThemeState];
};

function App() {
  const [themeState, setThemeState] = useEffectDarkMode();

  let toggleTheme = () => {
    const dark = !themeState.dark;
    localStorage.setItem("dark", JSON.stringify(dark));
    setThemeState({ ...themeState, dark });

    if (dark) changeBodyThemeClass("dark", "light");
    else changeBodyThemeClass("light", "dark");
  };

  useEffect(() => {
    initializeReactGA();
  }, []);

  if (!themeState.hasThemeLoaded) {
    return <div />;
  }

  return (
    <Router>
      <div id="bg" />
      <ThemeToggleButton toggleTheme={toggleTheme} theme={themeState} />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ScrollToTop />
      <Routes>
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
