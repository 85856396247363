import React, { useEffect, useState } from "react";
import { faAsterisk, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faGithubAlt,
  faInstagram,
  faLinkedin,
  faSteam,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import ButtonCollection from "../components/ButtonCollection.js";
import { Helmet } from "react-helmet";
import LinkButton from "../components/LinkButton.js";
import ReactGA from "react-ga";

//My own components

// fontawesome imports

function Home(props) {
  const connectionButtons = [
    {
      icon: faGithubAlt,
      link: "https://github.com/Ynng",
      colorCssVar: "--github-color",
      tip: "Github",
    },
    {
      icon: faLinkedin,
      link: "https://www.linkedin.com/in/ynng/",
      colorCssVar: "--linkedin-color",
      tip: "LinkedIn",
    },
    {
      icon: faEnvelope,
      link: "mailto:k255huan@uwateroo.ca",
      colorCssVar: "--gmail-color",
      tip: "Email Me",
    },
    {
      icon: faAsterisk,
      link: "https://dmoj.ca/user/Ynng11626",
      colorCssVar: "--dmoj-color",
      tip: "DMOJ Profile",
    },
    {
      icon: faYoutube,
      link: "https://www.youtube.com/channel/UC5qAOjtSdCkPEy1BUM78ruw/featured?view_as=subscriber",
      colorCssVar: "--youtube-color",
      tip: "Youtube",
    },
    {
      icon: faInstagram,
      link: "https://www.instagram.com/_ynng_/",
      colorCssVar: "--instagram-color",
      tip: "Instagram",
    },
    {
      icon: faDiscord,
      link: "https://discord.gg/HMZrmM6",
      colorCssVar: "--discord-color",
      tip: "Discord",
    },
    {
      icon: faSteam,
      link: "https://steamcommunity.com/id/Ynng_/",
      colorCssVar: "--steam-color",
      tip: "Steam",
    },
  ];
  const additionalButtons = [
    {
      text: "Resume",
      link: "/Kevin_Resume.pdf",
    },
    {
      text: "Portfolio",
      routerLink: "/portfolio",
    },
  ];

  return (
    <div className="my-card" {...props}>
      {/* <h1 className="name">Kevin Huang</h1> */}
      {/* <h1 className="title-desc">Software Developer & CS Student</h1> */}
      {/* <ButtonCollection buttons={additionalButtons} /> */}

      <div style={{ marginBottom: "3rem" }}>
        <p>
          👋 Hey, I'm <strong>Kevin</strong>! I'm a computer science student
          with a love for building cool stuff and tackling new challenges.
        </p>
        <p>
          When I'm not coding, you can find me crashing my drone into trees, or
          playing video games with my friends.
        </p>
      </div>
      <ButtonCollection buttons={connectionButtons} />
      <ButtonCollection buttons={additionalButtons} className="mt-2" />
    </div>
  );
}

export default Home;
