import React, { useEffect } from "react";
import { css, jsx } from "@emotion/react";
import {
  faAsterisk,
  faDownload,
  faEnvelope,
  faExternalLinkSquareAlt,
  faFileAlt,
  faImage,
  faList,
  faTrophy,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faGithubAlt,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import BackButton from "../components/BackButton.js";
import ButtonCollection from "../components/ButtonCollection.js";
import Fixate from "../images/Fixate.jpg";
import { Helmet } from "react-helmet";
import MainCard from "../content/MainCard";
import Noteworking from "../images/Noteworking.jpg";
import ReactGA from "react-ga";
import ResponsivePlayer from "../components/ResponsivePlayer.js";
import Scrollspy from "react-scrollspy";
import TTHelper from "../images/VEX-Tower-Takeover-Helper.jpg";
import TryCatchThrow from "../images/TryCatchThrow.jpg";
import UHS from "../images/UHS.jpg";
import UHSRobotics from "../images/UHSRobotics.jpg";
import VEXWebsite from "../images/VEXWebsite.jpg";
import VRCProgramming from "../images/VRCProgrammingCompressed.jpg";
import VRViewer from "../images/vr-viewer.jpg";
import UWAT from "../images/uwat.jpg";
import Flock from "../images/flock.jpg";
import Lodestone from "../images/lodestone.jpg";

function Portfolio() {
  useEffect(() => {
    console.log(
      "now visiting " + window.location.pathname + window.location.search
    );
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="my-container">
      <Helmet>
        <title>Portfolio | Kevin Huang</title>
        <meta
          name="description"
          content="Kevin Huang's projects and involvements"
        />
      </Helmet>
      <BackButton target="/" />
      <div className="content-with-scrollspy portfolio-page">
        <Scrollspy
          items={[
            "main_card",
            "",
            "lodestone",
            "uwat",
            "",
            "flock",
            "leroi",
            "uhs_robotics",
            "cs_club",
            "",
            "uhs_server",
            "robotics_website",
            "try_catch_throw",
            "fixate",
            "noteworking",
            "",
            "vex_website",
            "vrc_helper",
            "vr_viewer",
          ]}
          currentClassName="is-current"
          className="scrollspy"
          offset={-300}
        >
          <li>
            <a href="#main_card">Summary</a>
          </li>
          <li className="year">
            <a href="#ongoing">Ongoing</a>
          </li>
          <li>
            <a href="#lodestone">Gamer Server Tool</a>
          </li>
          <li>
            <a href="#uwat">VEXU Robotics</a>
          </li>
          <li className="year">
            <a href="#2021">2021</a>
          </li>
          <li>
            <a href="#flock">Goose Tracker</a>
          </li>
          <li>
            <a href="#leroi">VEX Robotics</a>
          </li>
          <li>
            <a href="#uhs_robotics">FRC Robotics</a>
          </li>
          <li>
            <a href="#cs_club">CS Club</a>
          </li>
          <li className="year">
            <a href="#2020">2020</a>
          </li>
          <li>
            <a href="#uhs_server">Discord Bot</a>
          </li>
          <li>
            <a href="#robotics_website">Club Website</a>
          </li>
          <li>
            <a href="#try_catch_throw">VR Game</a>
          </li>
          <li>
            <a href="#fixate">Distraction Blocker</a>
          </li>
          <li>
            <a href="#noteworking">Smart Calculator</a>
          </li>
          <li className="year">
            <a href="#2019">2019</a>
          </li>
          <li>
            <a href="#vex_website">Team Website</a>
          </li>
          <li>
            <a href="#vrc_helper">Competition App</a>
          </li>
          <li>
            <a href="#vr_viewer">VR Mesh Viewer</a>
          </li>
        </Scrollspy>

        {/* <h1 className="title">Nice to see you!</h1> */}

        {/* <MainCard id="main_card" style={{ marginBottom: "5em" }} /> */}

        <h1 className="title">Portfolio</h1>

        <div className="my-card" id="disclaimer">
          <p>
            This page does <b>not</b> contain work experience, please see my{" "}
            <a href="/Kevin_Resume.pdf">resume</a> or{" "}
            <a href="https://www.linkedin.com/in/ynng/">LinkedIn</a> for that.
          </p>
        </div>

        <h1 className="alignCenter" id="ongoing">
          Ongoing
        </h1>

        <div className="my-card" id="lodestone">
          <h1>
            Lodestone<span className="h4 title-detail">/Co-Founder</span>
            <h5 className="subtitle">2021 - present</h5>
          </h1>
          <div className="media-container center">
            <img
              src={Lodestone}
              alt="Figma mockup of Lodestone"
              draggable="false"
            />
          </div>
          <p>
            A free, open source server hosting tool for Minecraft and other
            multiplayers
          </p>
          <p>
            We feature a desktop app built with <b>Rust</b> for easy server
            management and a web app built with <b>React</b> for remote server
            management.
          </p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/Lodestone-Team",
                tip: "Github",
              },
              {
                icon: faExternalLinkSquareAlt,
                colorCssVar: "--visit-color",
                link: "https://lodestone.cc/",
                tip: "Dashboard (WIP)",
              },
            ]}
            className="left"
          />
        </div>

        <div className="my-card" id="uwat">
          <h1>
            UWAT VEXU <span className="h4 title-detail">/Programming Lead</span>
            <h5 className="subtitle">2021 - present</h5>
          </h1>
          <div className="media-container">
            <img
              src={UWAT}
              alt="A photo of our robot from the 2021-2022 season"
              draggable="false"
            />
          </div>
          <p>
            <b>Programming Lead</b> for the UWAT VEX U team.
          </p>
          <p>
            We competed at the world championship in Houston, Texas in May 2022.
          </p>
          <p>
            Integrated Jetson Nano with our robot to process vision tracking.
            Implemented serial communication between the Jetson and the robot
            brain through USB with CRC32 checksums to ensure data integrity.
          </p>
          <p>
            Related: <a href="#leroi">Highschool VEX</a>
          </p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/UW-VexU",
                tip: "Github (Code is private)",
              },
            ]}
            className="left"
          />
        </div>

        <h1 className="alignCenter" id="2021">
          2021
        </h1>
        <div className="my-card" id="flock">
          <h1>
            Flock: Goose Tracker
            <h5 className="subtitle">2021 September</h5>
          </h1>
          <div className="media-container center">
            <img
              src={Flock}
              alt="Screenshot of the flock goose tracking app"
              draggable="false"
            />
          </div>
          <p>A PWA that finds goose from a photo and pins it on google maps.</p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/CheatCod/Hack-The-North-2021",
                tip: "Github",
              },
              {
                icon: faFileAlt,
                colorCssVar: "--devpost-color",
                link: "https://devpost.com/software/flock-9sw8ib",
                tip: "DevPost",
              },
            ]}
            className="left"
          />
        </div>

        <div className="my-card" id="leroi">
          <h1>
            LeRoi Robotics{" "}
            <span className="h4 title-detail">/Programming Lead</span>
            <h5 className="subtitle">2019 - 2021</h5>
          </h1>
          <div
            className="media-container center"
            style={{
              backgroundImage:
                "url(https://i.ytimg.com/vi/53xpT4EiF6M/maxresdefault.jpg)",
            }}
          >
            <ResponsivePlayer
              url="https://www.youtube.com/watch?v=53xpT4EiF6M"
              controls={true}
            />
          </div>
          <p>
            <b>Programming Lead</b> of LeRoi Robotics (VEX Robotics Competition
            Team 81208X).
          </p>
          <p>
            <b>World Champions</b> of 2020 - 2021.
          </p>
          <p>
            We achieved the max score in both autonomous and manual formats in
            just <b>35</b> of the 60 seconds time limit. Of the 8 teams that
            achieved the max score, no other team did it in less than 45
            seconds.
          </p>
          {/* <p>
            I self-taught various robotic concepts and implemented them in C++
            <ul>
              <li>Wheel encoder, IMU and camera based positioning systems</li>
              <li>1D motion profiling</li>
              <li>mecanum drive and x-drive inverse and forward kinematics</li>
              <li>PID, pure-pursuit and ramsete controllers</li>
            </ul>
          </p> */}
          <p>
            Related: <a href="#leroi">VEX U</a>,{" "}
            <a href="#vex_website">Team Website</a>,{" "}
            <a href="#vrc_helper">Strategizing App</a>
          </p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/81208X/VRC2020",
                tip: "Early Season Code Release",
              },
              {
                icon: faExternalLinkSquareAlt,
                colorCssVar: "--visit-color",
                link: "https://www.leroirobotics.com/",
                tip: "Team Website",
              },
              {
                icon: faUsers,
                colorCssVar: "--credits-color",
                link: "https://www.leroirobotics.com/#team",
                tip: "Team Members",
              },
              {
                icon: faYoutube,
                colorCssVar: "--youtube-color",
                link: "https://www.youtube.com/watch?v=QoBwNy-vnsQ&list=PLnlKoBZU3IKec5B33RH_Wa4iVERsDyfy2&index=1",
                tip: "Videos",
              },
              {
                icon: faTrophy,
                colorCssVar: "--images-color",
                link: "https://www.robotevents.com/robot-competitions/vex-robotics-competition/RE-VRC-21-3836.html#results",
                tip: "Championship Rankings",
              },
            ]}
            className="left"
          />
        </div>
        <div className="my-card" id="uhs_robotics">
          <h1>
            UHS Robotics{" "}
            <span className="h4 title-detail">/Club President</span>
            <h5 className="subtitle">2019 - 2021</h5>
          </h1>
          <div
            className="media-container center"
            style={{
              backgroundImage:
                "url(https://i.ytimg.com/vi/j7h64w9ww2k/maxresdefault.jpg)",
            }}
          >
            <ResponsivePlayer
              url="https://www.youtube.com/watch?v=j7h64w9ww2k"
              controls={true}
            />
          </div>
          <p>
            <b>Team Captain</b> and <b>Club President</b> of Unionville High
            School Robotics Club (FIRST Robotics Competition Team 7721) since
            2020 September. Organized virtual events for the club of 150+
            members.
          </p>
          <p>
            <b>Programming Lead</b> since 2019 September. Programmed competition
            robot in Java with a team of 10.
          </p>
          <p>
            Our competition season was unfortunately cut short due to the
            COVID-19 pandemic.
          </p>
          <p>
            Related: <a href="#robotics_website">Team Website</a>
          </p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/UHSRobotics/FRC-2020",
                tip: "Robot Code",
              },
              {
                icon: faExternalLinkSquareAlt,
                colorCssVar: "--visit-color",
                link: "https://www.uhsrobotics.org/",
                tip: "Website",
              },
              {
                icon: faUsers,
                colorCssVar: "--credits-color",
                link: "https://www.uhsrobotics.org/team.html#top",
                tip: "Team Members",
              },
              {
                icon: faYoutube,
                colorCssVar: "--youtube-color",
                link: "https://www.youtube.com/channel/UCM4zyy81QGAcxW_ovfChR4g",
                tip: "Youtube Channel",
              },
            ]}
            className="left"
          />
        </div>
        <div className="my-card" id="cs_club">
          <h1>
            UHS Comp Sci <span className="h4 title-detail">/President</span>
            <h5 className="subtitle">2018 - 2021</h5>
          </h1>
          <div
            className="media-container center"
            style={{
              backgroundImage:
                "url(https://i.ytimg.com/vi/Zi6NvDbyAfY/maxresdefault.jpg)",
            }}
          >
            <ResponsivePlayer
              url="https://www.youtube.com/watch?v=Zi6NvDbyAfY"
              controls={true}
            />
          </div>
          <p>
            Co-founded the computer science club at Unionville Highschool with{" "}
            <a href="https://www.peterjiang.net/">Peter Jiang</a> and{" "}
            <a href="https://czhu1217.github.io/">Cindy Zhu</a>. Hosted
            workshops with{" "}
            <a href="https://arcslogger.github.io/">Wilbur Zhang</a> and{" "}
            <a href="https://github.com/C0W0">Terry Zha</a>.
          </p>
          <p>
            We taught members Java, C++, competitive programming and Unity game
            development on a weekly basis online.
          </p>
          <ButtonCollection
            buttons={[
              {
                icon: faExternalLinkSquareAlt,
                colorCssVar: "--visit-color",
                link: "https://uhscs.club/",
                tip: "Website",
              },
              {
                icon: faYoutube,
                colorCssVar: "--youtube-color",
                link: "https://www.youtube.com/watch?v=Zi6NvDbyAfY",
                tip: "Introduction Video",
              },
              {
                icon: faList,
                colorCssVar: "--dmoj-color",
                link: "https://dmoj.ca/organization/125-unionville-hs/users",
                tip: "Check DMOJ Ranking",
              },
            ]}
            className="left"
          />
        </div>
        <h1 className="alignCenter" id="2020">
          2020
        </h1>
        <div className="my-card" id="uhs_server">
          <h1>
            UHS Community bot & server
            <h5 className="subtitle">2020 September</h5>
          </h1>
          <div className="media-container">
            <img
              src={UHS}
              alt="Edited screenshot of the server"
              draggable="false"
            />
          </div>
          <p>
            Highly customizable <b>discord bot</b> for managing a discord server
            I made for my high school.
          </p>
          <p>
            Manages permission, creates roles and channels automatically. Has
            specific and user friendly error messages.
          </p>
          <p>Made in node.js using the discord.js package.</p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/Ynng/Highschool-Discord",
                tip: "Github",
              },
              {
                icon: faUsers,
                colorCssVar: "--credits-color",
                link: "https://github.com/Ynng/Highschool-Discord#contributors",
                tip: "Contributors",
              },
            ]}
            className="left"
          />
        </div>
        <div className="my-card" id="robotics_website">
          <h1>
            UHS Robotics Website<h5 className="subtitle">2020 Summer</h5>
          </h1>
          <div className="media-container center-up">
            <img
              src={UHSRobotics}
              alt="screenshot of the website"
              draggable="false"
            />
          </div>
          <p>
            A website made for the Unionville High School Robotics Club, FIRST
            Robotics Competition Team 7721 / 849.
          </p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/UHSRobotics/UHSRobotics.github.io",
                tip: "Github",
              },
              {
                icon: faExternalLinkSquareAlt,
                colorCssVar: "--visit-color",
                link: "https://www.uhsrobotics.org/",
                tip: "Visit",
              },
              {
                icon: faUsers,
                colorCssVar: "--credits-color",
                link: "https://www.uhsrobotics.org/credits.html",
                tip: "Contributors",
              },
            ]}
            className="left"
          />
        </div>
        <div className="my-card" id="try_catch_throw">
          <h1>
            Try-Catch-Throw: VR Game<h5 className="subtitle">2020 June</h5>
          </h1>
          <div className="media-container">
            <img
              src={TryCatchThrow}
              alt="render of the game"
              draggable="false"
            />
          </div>
          <p>
            A <b>VR game</b> made in Unity for a school project, where you
            protect a castle from trebuchets using a "Force Pull" wand, inspired
            by the gravity gloves from Half-Life: Alyx.
          </p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/Ynng/Try-Catch-Throw",
                tip: "Github",
              },
              {
                icon: faDownload,
                colorCssVar: "--download-color",
                link: "https://github.com/Ynng/Try-Catch-Throw/releases/latest",
                tip: "Download",
              },
              {
                icon: faImage,
                colorCssVar: "--images-color",
                link: "https://github.com/Ynng/Try-Catch-Throw#screenshots",
                tip: "Screenshots",
              },
            ]}
            className="left"
          />
        </div>
        <div className="my-card" id="fixate">
          <h1>
            Fixate: Distraction Blocker<h5 className="subtitle">2020 May</h5>
          </h1>
          <div
            className="media-container center"
            style={{
              backgroundImage:
                "url(https://i.ytimg.com/vi/FlUhG99RqHs/maxresdefault.jpg)",
            }}
          >
            <ResponsivePlayer
              url="https://www.youtube.com/watch?v=FlUhG99RqHs"
              controls={true}
            />
          </div>
          {/* <div className="media-container"> <img src={Fixate} alt="screenshot of Fixate" draggable="false"/> </div>*/}
          <p>
            A Chrome extension that blocks distractions online based on "current
            focus" as described in plain English.
          </p>
          <p>
            E.g. youtube hosts both cat videos and programming tutorials,
            setting current focus to “database”, "computer science" or any
            programming related terms will block cat videos but leave
            programming tutorials accessible.
            {/* ; unless your current focus is cat related, such
            as "cat app developer", then you can see both types of videos. */}
          </p>
          <p>Made with TensorFlow, Google Cloud and Python.</p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/Ynng/TOHacks2020",
                tip: "Github",
              },
              {
                icon: faFileAlt,
                colorCssVar: "--devpost-color",
                link: "https://devpost.com/software/fixate-0m2x8d",
                tip: "DevPost",
              },
              {
                icon: faDownload,
                colorCssVar: "--download-color",
                link: "https://github.com/Ynng/TOHacks2020/releases/latest",
                tip: "Download",
              },
              {
                icon: faYoutube,
                colorCssVar: "--youtube-color",
                link: "https://www.youtube.com/watch?v=FlUhG99RqHs",
                tip: "Video",
              },
            ]}
            className="left"
          />
        </div>
        <div className="my-card" id="noteworking">
          <h1>
            Noteworking: Smart Calculator
            <h5 className="subtitle">2020 January</h5>
          </h1>
          <div className="media-container center">
            <img
              src={Noteworking}
              alt="Screenshot of Noteworking"
              draggable="false"
            />
          </div>
          <p>
            A smart calculator that automatically finds and applies the correct
            formula given enough inputs. A custom text editor with an
            autocomplete engine was also made for this project.
          </p>
          <p>Made in vanilla html/css/javascript.</p>

          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/Ynng/Starter-Hacks-2020",
                tip: "Github",
              },
              {
                icon: faFileAlt,
                colorCssVar: "--devpost-color",
                link: "https://devpost.com/software/noteworking",
                tip: "DevPost",
              },
              {
                icon: faExternalLinkSquareAlt,
                colorCssVar: "--visit-color",
                link: "https://note.working.ynng.ca",
                tip: "Visit",
              },
            ]}
            className="left"
          />
        </div>
        <h1 className="alignCenter" id="2019">
          2019
        </h1>
        <div className="my-card" id="vex_website">
          <h1>
            Team Website<h5 className="subtitle">2019 December</h5>
          </h1>
          <div className="media-container center">
            <img
              src={VEXWebsite}
              alt="Screenshot of 81208X team website"
              draggable="false"
            />
          </div>
          <p>
            A website made with bootstrap for my VEX Robotics Competition Team,
            81208X LeRoi Robotics.
          </p>
          <p>One of the my very first websites.</p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/81208X/81208X.github.io",
                tip: "Github",
              },
              {
                icon: faExternalLinkSquareAlt,
                colorCssVar: "--visit-color",
                link: "https://81208x.github.io/",
                tip: "Visit",
              },
            ]}
            className="left"
          />
        </div>
        <div className="my-card" id="vrc_helper">
          <h1>
            VRC Tower Takeover Helper<h5 className="subtitle">2019 November</h5>
          </h1>
          <div className="media-container center">
            <img
              src={TTHelper}
              alt="Screenshot of VRC Tower Takeover Helper"
              draggable="false"
            />
          </div>
          <p>
            A web app made for touchscreens for VEX Robotics Competition season
            2019-2020. This app helps our{" "}
            <a href="#vex_programming_lead">team</a> to track scores as matches
            happen and provides recommendations on what to do next during the
            match. It was designed to require minimal attention when using.
          </p>
          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/Ynng/VEX-Tower-Takeover-Helper",
                tip: "Github",
              },
              {
                icon: faExternalLinkSquareAlt,
                colorCssVar: "--visit-color",
                link: "https://tt-helper.ynng.ca/",
                tip: "Visit",
              },
            ]}
            className="left"
          />
        </div>
        <div className="my-card" id="vr_viewer">
          <h1>
            VR Mesh Viewer<h5 className="subtitle">2019 - 2021</h5>
          </h1>
          <div className="media-container center">
            <img
              src={VRViewer}
              alt="First screenshot of the program working"
              draggable="false"
            />
          </div>
          <p>
            A simple 3d mesh viewer for <code>Steam VR</code>, made using{" "}
            <code>OpenGL</code> and{" "}
            <a href="https://libigl.github.io/">
              <code>libigl</code>
            </a>
            , with <a href="https://czhu1217.github.io/">Cindy Zhu</a> and
            immense help from{" "}
            <a href="http://www.sarahkushner.com/">Sarah Kushner</a> and
            Professor{" "}
            <a href="https://www.cs.toronto.edu/~jacobson/">Alec Jacobson</a>.
          </p>
          <p>
            This project taught me a lot about computer graphics, different
            projection matrices, frame buffers, etc, and gave me an early look
            into practical applications of linear algebra.
          </p>

          <ButtonCollection
            buttons={[
              {
                icon: faGithubAlt,
                colorCssVar: "--github-color",
                link: "https://github.com/Ynng/libigl-vr-viewer",
                tip: "Github",
              },
            ]}
            className="left"
          />
        </div>
        <h3 className="alignCenter">
          <a href="#body">Back To Top</a>
        </h3>
      </div>
    </div>
  );
}
export default Portfolio;
