import React, { useEffect, useState } from "react";
import { faAsterisk, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faDiscord,
  faGithubAlt,
  faInstagram,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import ButtonCollection from "../components/ButtonCollection.js";
import { Helmet } from "react-helmet";
import LinkButton from "../components/LinkButton.js";
import MainCard from "../content/MainCard";
import ReactGA from "react-ga";

//My own components

// fontawesome imports

function Home() {
  useEffect(() => {
    console.log(
      "now visiting " + window.location.pathname + window.location.search
    );
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="my-container center">
      <Helmet>
        <title>Home | Kevin Huang</title>
        <meta
          name="description"
          content="Home of Kevin Huang's personal website"
        />
      </Helmet>
      <MainCard/>
    </div>
  );
}

export default Home;
